import PLANS from '@/utils/plans'

export default {
  santi: {
    displayName: 'Santi',
    paddleVendorId: 130889,
    coupon: 'santi',
    discount: 0.1,
    paddleProportions: {
      [PLANS.MONTHLY.id]: {
        split: '1',
        recurring: 2,
      },
      [PLANS.YEARLY.id]: {
        split: '0.35',
        recurring: 1,
      },
      [PLANS.LIFETIME.id]: {
        split: '0.25',
        recurring: 1,
      },
    },
  },
  nico: {
    displayName: 'Nico',
    paddleVendorId: 126350,
    coupon: 'santi',
    discount: 0.1,
    paddleProportions: {
      [PLANS.MONTHLY.id]: {
        split: '1',
        recurring: 2,
      },
      [PLANS.YEARLY.id]: {
        split: '0.35',
        recurring: 1,
      },
      [PLANS.LIFETIME.id]: {
        split: '0.25',
        recurring: 1,
      },
    },
  },
}
