import { useState, useRef, useEffect } from 'react'
import {
  Menu,
  X,
  Camera,
  Share,
  Download,
  FileText,
  ChevronDown,
  UserCheck,
  Users,
  ArrowRight,
} from 'react-feather'
import { Transition } from '@headlessui/react'
import Link from 'next/link'
import Logo from '../../public/img/logos/logo.svg'
import { useSession } from 'next-auth/client'
import { useRouter } from 'next/router'
import useOnClickOutside from '@/utils/useOnClickOutside'
import useOnEsc from '@/utils/useOnEsc'
import classNames from 'classnames'
import affiliates from '@/utils/affiliates'
import { useRecoilState } from 'recoil'
import { affiliateState } from '@/store'
import Image from 'next/image'

/* eslint-disable @next/next/no-img-element */

export default function LandingMenu() {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showFeaturesMenu, setShowFeaturesMenu] = useState(false)
  const [showCasesMenu, setShowCasesMenu] = useState(false)
  const [showShadow, setShowShadow] = useState(false)
  const [affiliateName] = useRecoilState(affiliateState)
  const [session] = useSession()
  const router = useRouter()

  useEffect(() => {
    setShowMobileMenu(false)
  }, [router.pathname])

  useEffect(() => {
    function onScroll() {
      setShowShadow(window.pageYOffset > 10)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const menuStyle = {
    backdropFilter: 'saturate(180%) blur(5px)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  }

  const menuClassname = classNames('sticky top-0 z-20', {
    'shadow-sm': showShadow,
  })

  return (
    <div className={menuClassname} style={menuStyle}>
      <div className="max-w-7xl mx-auto w-full min-w-full">
        {affiliateName && <AffiliateBanner affiliateName={affiliateName} />}
        <div className="flex max-w-7xl mx-auto justify-between items-center px-4 py-4 sm:px-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link href="/" className="flex items-center justify-center">
              <a className="center">
                <span className="sr-only">Annotate.tv</span>
                <Logo className="h-8 w-auto" />
                <span className="pl-3 text-2xl font-bold">Annotate.tv</span>
              </a>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              onClick={() => setShowMobileMenu(true)}
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
            >
              <span className="sr-only">Open menu</span>
              <Menu className="h-6 w-6" />
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            <div className="relative">
              <button
                type="button"
                onClick={() => setShowFeaturesMenu(!showFeaturesMenu)}
                className="group clickable rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none"
              >
                <span>Features</span>
                <ChevronDown className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              </button>

              <FeaturesMenu {...{ showFeaturesMenu, setShowFeaturesMenu }} />
            </div>

            <div className="relative">
              <button
                type="button"
                onClick={() => setShowCasesMenu(!showCasesMenu)}
                className="group clickable rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none"
              >
                <span>Use Cases</span>
                <ChevronDown className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              </button>

              <CasesMenu {...{ showCasesMenu, setShowCasesMenu }} />
            </div>

            <Link href="/pricing">
              <a className="whitespace-nowrap clickable text-base font-medium text-gray-500 hover:text-gray-900">
                Pricing
              </a>
            </Link>

            <Link href="/demo">
              <a className="whitespace-nowrap clickable text-base font-medium text-gray-500 hover:text-gray-900">
                Demo
              </a>
            </Link>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {session ? (
              <Link href="/videos">
                <a className="whitespace-nowrap clickable-opacity inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-blue-700 bg-blue-100">
                  {session.user.image && (
                    <div className="center mr-2">
                      <img
                        width={24}
                        height={24}
                        style={{ minWidth: '24px', minHeight: '24px' }}
                        alt="avatar"
                        className="rounded-full"
                        src={session.user.image}
                      />
                    </div>
                  )}
                  My Videos
                </a>
              </Link>
            ) : (
              <>
                <Link href="/login">
                  <a className="whitespace-nowrap clickable-opacity text-base font-medium text-gray-500">
                    Log in
                  </a>
                </Link>
                <Link href="/signup">
                  <a className="ml-8 whitespace-nowrap clickable-opacity inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-blue-700 bg-blue-100">
                    Sign up free
                  </a>
                </Link>
              </>
            )}
          </div>
        </div>

        <MobileMenu {...{ showMobileMenu, setShowMobileMenu }} />
      </div>
    </div>
  )
}

function MobileMenu({ showMobileMenu, setShowMobileMenu }) {
  const container = useRef(null)

  useOnClickOutside({
    container,
    show: showMobileMenu,
    setShow: setShowMobileMenu,
  })
  useOnEsc({ show: showMobileMenu, setShow: setShowMobileMenu })

  return (
    <Transition
      show={showMobileMenu}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        ref={container}
        className="absolute top-0 inset-x-0 p-2 z-10 transition transform origin-top-right md:hidden"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <a href="#" className="flex items-center justify-center">
                  <span className="sr-only">Annotate.tv</span>
                  <Logo className="h-8 w-auto" />
                  <span className="pl-3 text-2xl font-bold">Annotate.tv</span>
                </a>
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                >
                  <span className="sr-only">Close menu</span>
                  <X
                    className="h-6 w-6"
                    onClick={() => setShowMobileMenu(false)}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="py-6 px-5">
            <div className="mb-10">
              <Link href="/signup">
                <a className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary">
                  Sign up free
                </a>
              </Link>
              <p className="mt-6 text-center text-base font-medium text-gray-500">
                Already have an account?
                <Link href="/login">
                  <a className="text-primary ml-2">Log in</a>
                </Link>
              </p>
            </div>

            <Link href="/pricing">
              <a className="text-base font-medium text-gray-900 hover:text-gray-700 block mb-5">
                Pricing
              </a>
            </Link>

            <Link href="/demo">
              <a className="text-base font-medium text-gray-900 hover:text-gray-700 block mb-5">
                Demo
              </a>
            </Link>
            <div className="grid grid-rows-4 gap-4">
              <Link href="/features/transcript">
                <a className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <FileText className="h-6 w-6" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Transcript
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Search the full transcript of your videos and find exactly
                      what you're looking for.
                    </p>
                  </div>
                </a>
              </Link>

              <Link href="/features/text-from-video">
                <a className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <Camera className="h-6 w-6" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Text from video
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Copy the text appearing in your video into your editor,
                      just with one click.
                    </p>
                  </div>
                </a>
              </Link>

              <Link href="/features/export">
                <a className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <Download className="h-6 w-6" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Export
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Export your notes to PDF, Evernote and Notion. Or sync
                      them with Readwise.
                    </p>
                  </div>
                </a>
              </Link>

              <Link href="/features/share">
                <a className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <Share className="h-6 w-6" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">Share</p>
                    <p className="mt-1 text-sm text-gray-500">
                      Share your notes with friends or classmates and receive
                      feedback.
                    </p>
                  </div>
                </a>
              </Link>

              <Link href="/cases/learning">
                <a className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <UserCheck className="h-6 w-6" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Learning
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Actively taking notes can help you focus and better
                      understand main concepts.
                    </p>
                  </div>
                </a>
              </Link>

              <Link href="/cases/teaching">
                <a className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                    <Users className="h-6 w-6" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Teaching
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Make the life easier for your audience. Link to any book,
                      paper or docs you mention.
                    </p>
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

function FeaturesMenu({ showFeaturesMenu, setShowFeaturesMenu }) {
  const container = useRef(null)
  const router = useRouter()

  useOnClickOutside({
    container,
    show: showFeaturesMenu,
    setShow: setShowFeaturesMenu,
  })
  useOnEsc({ show: showFeaturesMenu, setShow: setShowFeaturesMenu })

  function isActive(name) {
    return router.pathname.includes(name)
  }

  function getFeatureClassName(name) {
    return classNames(
      'clickable -m-3 p-3 flex items-start rounded-lg hover:bg-gray-100',
      {
        'bg-gray-100 pointer-events-none': isActive(name),
      }
    )
  }

  return (
    <Transition
      show={showFeaturesMenu}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div
        ref={container}
        className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md md:max-w-3xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
            <Link href="/features/transcript">
              <a className={getFeatureClassName('/transcript')}>
                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                  <FileText className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    Transcript
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Search the full transcript of your videos and find exactly
                    what you're looking for.
                  </p>
                </div>
              </a>
            </Link>

            <Link href="/features/text-from-video">
              <a className={getFeatureClassName('/text-from-video')}>
                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                  <Camera className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    Text from video
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Copy the text appearing in your video into your editor, just
                    with one click.
                  </p>
                </div>
              </a>
            </Link>

            <Link href="/features/export">
              <a className={getFeatureClassName('/export')}>
                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                  <Download className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">Export</p>
                  <p className="mt-1 text-sm text-gray-500">
                    Export your notes to PDF, Evernote and Notion. Or sync them
                    with Readwise.
                  </p>
                </div>
              </a>
            </Link>

            <Link href="/features/share">
              <a className={getFeatureClassName('/share')}>
                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                  <Share className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">Share</p>
                  <p className="mt-1 text-sm text-gray-500">
                    Share your notes with friends or classmates and receive
                    feedback.
                  </p>
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Transition>
  )
}

function CasesMenu({ showCasesMenu, setShowCasesMenu }) {
  const container = useRef(null)
  const router = useRouter()

  function isActive(name) {
    return router.pathname.includes(name)
  }

  function getCaseClassName(name) {
    return classNames('clickable -m-3 p-3 block rounded-md hover:bg-gray-100', {
      'bg-gray-100 pointer-events-none': isActive(name),
    })
  }

  useOnClickOutside({
    container,
    show: showCasesMenu,
    setShow: setShowCasesMenu,
  })
  useOnEsc({ show: showCasesMenu, setShow: setShowCasesMenu })

  return (
    <Transition
      show={showCasesMenu}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div
        ref={container}
        className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <Link href="/cases/learning">
              <a className={getCaseClassName('/learning')}>
                <p className="text-base font-medium text-gray-900">Learning</p>
                <p className="mt-1 text-sm text-gray-500">
                  Actively taking notes can help you focus and better understand
                  main concepts.
                </p>
              </a>
            </Link>

            <Link href="/cases/teaching">
              <a className={getCaseClassName('/teaching')}>
                <p className="text-base font-medium text-gray-900">Teaching</p>
                <p className="mt-1 text-sm text-gray-500">
                  Make the life easier for your audience. Link to any book,
                  paper or docs you mention.
                </p>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Transition>
  )
}

function AffiliateBanner({ affiliateName }) {
  const affiliate = affiliates[affiliateName]

  return (
    <Link
      className="center"
      href={{
        pathname: '/signup',
        query: {
          callbackUrl: `${document.location.origin}/upgrade?affiliate=${affiliateName}`,
        },
      }}
    >
      <div className="relative clickable-opacity bg-indigo-600 center px-4 py-2 sm:px-6 cursor-pointer">
        <img
          className="rounded-full"
          width={35}
          height={35}
          src={`/img/affiliate/${affiliateName}.jpg`}
          alt={affiliate.displayName}
        />
        <div className="center font-medium text-white ml-3">
          <span className="mr-1">
            You've been referred by {affiliate.displayName}.
          </span>{' '}
          You are getting a {affiliate.discount * 100}% discount.
          <span className="flex font-bold underline ml-2">
            Sign up
            <ArrowRight />
          </span>
        </div>
      </div>
    </Link>
  )
}
