import Menu from './Menu'
import Footer from './Footer'
import { NextSeo } from 'next-seo'
import getSeo from '@/utils/seo'

export default function LandingSection({
  children,
  title,
  description = '',
  canonical,
}) {
  return (
    <>
      <NextSeo {...getSeo({ title, description, canonical })} />

      <div className="flex flex-col min-h-screen">
        <Menu />
        <main className="flex flex-1 flex-col">{children}</main>
        <Footer />
      </div>
    </>
  )
}
